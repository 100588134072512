import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import Box from "./box"
import Icon from "./icon"

const StackedButton = ({
  icon,
  children,
  isDisabled,
  href,
  onClick,
  to,
  ...restProps
}) => {
  const buttonColor = isDisabled ? "disabled" : "primary"

  return (
    <Box
      as={to ? Link : href ? "a" : onClick ? "button" : "span"}
      to={to || null}
      href={href || null}
      onClick={onClick || null}
      sx={{
        appearance: "none",
        bg: "transparent",
        cursor: isDisabled ? "not-allowed" : "pointer",
        textAlign: "center",
        color: isDisabled ? buttonColor : "text",
        fontSize: 2,
        width: 24,
        height: 24,
        fontFamily: "sans",
        fontWeight: "bold",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        ":hover, :focus": isDisabled
          ? null
          : {
              bg: buttonColor,
            },
        ".stacked-button-icon": {
          display: "none",
        },
        ":hover .stacked-button-icon, :focus .stacked-button-icon": isDisabled
          ? null
          : {
              display: "block",
            },
        ".stacked-button-text": {
          display: "flex",
        },
        ":hover .stacked-button-text, :focus .stacked-button-text": isDisabled
          ? null
          : {
              display: "none",
            },
      }}
      {...restProps}
    >
      <Box as="span" className="stacked-button-icon">
        <Icon size={12} icon={icon} color="black" />
      </Box>
      <Box
        as="div"
        className="stacked-button-text"
        sx={{
          flex: "1 0 auto",
          width: "100%",
          height: "100%",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            bg: isDisabled ? "disabled" : "primary",
            color: isDisabled ? "whisper" : "black",
            display: "flex",
            flex: "1 0 auto",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            p: 2,
          }}
        >
          <Icon icon={icon} size={8} />
        </Box>
        <Box
          sx={{
            bg: isDisabled ? "disabled" : "black",
            color: isDisabled ? "whisper" : "white",
            flexShrink: "none",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            p: 2,
          }}
        >
          {children}
        </Box>
      </Box>
    </Box>
  )
}

StackedButton.propTypes = {
  href: PropTypes.string,
  isDisabled: PropTypes.bool,
  icon: PropTypes.string,
  onClick: PropTypes.func,
  to: PropTypes.string,
}

StackedButton.defaultProps = {
  icon: "arrow-right",
}

export default StackedButton
