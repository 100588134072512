import React from "react"
import { navigate } from "gatsby"
import {
  getCourseNavigation,
  getCourseProgress,
} from "../../../../store/courses"
import LayoutCourse from "../../../../layouts/course"

import Box from "../../../../components/box"
import Paragraph from "../../../../components/paragraph"
import CourseSummary from "../../../../components/course-summary"
import CourseFooterBool from "../../../../components/course-footer-bool"
import Heading from "../../../../components/heading"
import Seo from "../../../../components/seo"
import Stack from "../../../../components/stack"
import { postVote } from "../../../../api"

const Page = () => {
  const navigation = getCourseNavigation({ courseId: "reiseerinnerung" })
  const progress = getCourseProgress({ courseId: "reiseerinnerung" })

  return (
    <LayoutCourse
      footer={
        <CourseFooterBool
          title="Hat dir der Kurs gefallen?"
          onSubmit={async (positive) => {
            try {
              await postVote({
                title: "reiseerinnerung",
                upvoted: positive,
              })
            } catch (error) {}
            navigate("/kurse/reiseerinnerung/feedback", {
              state: { positive },
            })
          }}
        />
      }
      navigation={navigation}
    >
      <Seo title="Zusammenfassung" />
      <Box>
        <Stack>
          <Heading as="h2" level={2}>
            Super! Du hast es geschafft.
          </Heading>
          <CourseSummary {...progress} />
          <Paragraph>
            In diesem Kurs hast du eine ganz schöne Reise zurückgelegt: Nachdem
            du dich im 1. Kapitel von Hölderlins Gedicht ›Andenken‹ bis nach
            Bordeaux hast führen lassen, bist du im 2. Kapitel in die
            Dichterwerkstatt eingestiegen.
          </Paragraph>
          <Paragraph>
            Du hast erfahren, dass ›Andenken‹ 1803 mit einer ganzen Reihe
            weiterer Reisegedichte entstanden ist und 1808 erstmals
            veröffentlicht wurde. Im Manuskript ist lediglich der Entwurf zur
            letzten Strophe erhalten. Doch dieser zeigte bereits, wieviel
            Hölderlin in seinen Gedichten gestrichen, korrigiert und
            umgearbeitet hat.
          </Paragraph>
          <Paragraph>
            Beim Zählen der Strophen und Verse hast du bemerkt, dass das Gedicht
            insgesamt sehr regelmäßig aufgebaut ist. Die letzte Strophe weicht
            jedoch minimal davon ab und erhält dadurch eine Sonderstellung.
          </Paragraph>
          <Paragraph>
            Doch im freirhythmisch gestalteten Versschema hat Hölderlin ein
            metrisches Muster versteckt: den Adonius. Über das gesamte Gedicht
            hinweg läuft diese Klangfolge auf den letzten Vers zu, der dadurch
            zu einer Art Höhepunkt wird. Darüber hinaus hat Hölderlin aber auch
            durch seine Verwendung von Konsonanten und Vokalen eine besondere
            Sprachmelodie erzeugt.
          </Paragraph>
          <Paragraph>
            Im Videobeitrag von Christian Reiner hast du gesehen, wie je nach
            Sprecher eine ganz eigene Wirkung entfalten kann. Zuletzt hast du
            selbst ausprobiert, wie sich das Gedicht sprechen und in Bewegung
            übersetzen lässt und hast dazu ein eigenes Video angefertigt.
          </Paragraph>
        </Stack>
      </Box>
    </LayoutCourse>
  )
}

export default Page
