import React from "react"
import Columns from "./columns"
import Column from "./column"
import StackedButton from "./stacked-button"
import Heading from "./heading"
import Inline from "./inline"

const CourseFooterBool = ({ title, to, onSubmit }) => {
  return (
    <Columns
      space={[6, 12]}
      alignY="center"
      alignX="right"
      collapse={[true, false]}
    >
      <Column width="content">
        <Heading level={5}>{title}</Heading>
      </Column>
      <Column width="content">
        <Inline space={6} alignY="center">
          <StackedButton
            icon="thumbs-up"
            to={to}
            onClick={() => {
              onSubmit(true)
            }}
          >
            Ja
          </StackedButton>
          <StackedButton
            icon="thumbs-down"
            to={to}
            onClick={() => {
              onSubmit(false)
            }}
          >
            Nein
          </StackedButton>
        </Inline>
      </Column>
    </Columns>
  )
}

export default CourseFooterBool
